import React, {useEffect, useState} from 'react';
import FormElementText from "../../Form/Element/Text";
import FormElementSelect from "../../Form/Element/Select";
import DietaryFilters from "./DietaryFilters/DietaryFilters";
import './AllergensMenu.scss'
import Collapse from "../../Bookings/Elements/Collapse/Collapse";
import MenuItem from "./Menu/MenuItem";
import {Api} from "../../Api/Api";
import FormBarSelect from "../../Form/Element/FormBarSelect";
import {Settings} from "../../Settings/Settings";
import FormRow from "../../Form/Row";
import Loading from "../../Utility/Loading/Loading";
import Notice from "../../Utility/Notice/Notice";
import {allergens, dietaryOptions} from "./allergens";
import {ModalHelper} from "../../Utility/Modal/AppModal";
import AllergensDeclaration from "./AllergensDeclaration/AllergensDeclaration";
import Tooltip from "../../Utility/Tooltip";

const AllergensMenu = () => {
    const initialAllergensState = [...allergens, ...dietaryOptions].map(item => ({
        name: item, checked: false
    }));

    const [loading, setLoading] = useState(false);
    const [menuData, setMenuData] = useState([]);
    const [intolerances, setIntolerances] = useState(initialAllergensState);
    const [uiFilters, setUiFilters] = useState([
        {name: 'Hide items that "may contain" allergens', value: 'hide_may_contain', checked: false},
        {name: 'Show calories', value: 'show_calories', checked: false}
    ]);
    const [filteredMenus, setFilteredMenus] = useState(menuData);
    const [location, setLocation] = useState('');
    const [search, setSearch] = useState('');
    const [type, setType] = useState('');
    const [menuTypes, setMenuTypes] = useState([]);

    const handleUiFiltersChange = (e) => {
        const name = e.target.name;
        const checked = e.target.checked;
        setUiFilters(uiFilters.map(item => item.name.toLowerCase() === name ? {...item, checked} : item))
    }

    const handleIntoleranceChange = (e) => {
        const name = e.target.name;
        const checked = e.target.checked;
        setIntolerances(intolerances.map(item => item.name.toLowerCase() === name ? {...item, checked} : item));
    }

    const loadMenu = () => {
        setLoading(true)
        Api.get(`menus/allergen-menu/${location}/${type}`).then((response) => {
            const filteredResponse = response.data.data.reduce((acc, curr) => {
                curr.items = curr.items.filter(item => item.name !== null)
                acc.push(curr);
                return acc;
            }, [])
            setMenuData(filteredResponse);
            setFilteredMenus(response.data.data)
        }).finally(() => setLoading(false))
    }
    const loadMenuTypes = () => {
        setLoading(true)
        Api.get(`menus/allergen-menu-types/${location}`).then((response) => {
            setMenuTypes(response.data.data)
        }).finally(() => setLoading(false));
    }

    const openDeclaration = () => {
        ModalHelper.openModal(
            <AllergensDeclaration/>,
            'full-page',
            <button className="btn" onClick={() => ModalHelper.closeModal()}>Accept</button>
        );
        ModalHelper.showXButton(false);
    }

    useEffect(() => {
        openDeclaration()
    }, []);

    useEffect(() => {
        if (!location) return;
        loadMenuTypes()
    }, [location]);

    useEffect(() => {
        if (!location || !type) return;
        loadMenu()
    }, [location, type]);

    useEffect(() => {
        if (!search) setFilteredMenus(menuTypes);
        const filtered = []
        menuData.forEach(m => {
            const menu = {
                category: m.category,
                items: m.items.filter(item => {
                    const name = item.name.toLowerCase();
                    const description = (item.description ?? '').toLowerCase();
                    return name.includes(search.toLowerCase()) || description.includes(search.toLowerCase());
                })
            }
            if (menu.items.length > 0) filtered.push(menu)
        })
        setFilteredMenus(filtered);
    }, [search])

    return (
        <div className='allergens-menu'>
            <DietaryFilters intolerances={intolerances} handleChange={handleIntoleranceChange} uiFilters={uiFilters}
                            handleUiFiltersChange={handleUiFiltersChange}/>

            <div className='allergens-menu-list'>
                <div className='allergens-menu-list-inputs'>
                    <FormRow label="Location">
                        <FormBarSelect
                            onChange={(e) => {
                                setLocation(e.target.value.toString());
                                setType('')
                            }}
                            selectedValue={location}
                            name="bar_id"
                            brand={Settings.brand}
                            value={location}
                        />
                    </FormRow>
                    <FormRow label='Select Menu'>
                        <FormElementSelect
                            name='menu-select'
                            useSelect2={true}
                            values={menuTypes?.sort((a, b) => a.name.localeCompare(b.name)).map(type => ({key: type.id, value: type.name}))}
                            selectedValue={type}
                            disabled={!Boolean(location)}
                            onChange={(e) => setType(e.target.value)}
                        />
                    </FormRow>
                </div>
                <ul className='allergens-menu-list-menu-items'>
                    {loading && <Loading/>}

                    {(!location || !type) &&
                        <Notice variant='info' name='info'>
                            {!location && 'Please select a location'}
                            {!type && location && 'Please select a menu'}
                        </Notice>
                    }

                    {location && type &&
                        <>
                            <FormRow label='Search'>
                                <FormElementText
                                    name='allergens'
                                    placeholder='Search menu item'
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                />
                            </FormRow>

                            {!filteredMenus.length > 0 ?
                                <p>No items meet your criteria</p> :
                                filteredMenus.map((menu, menuDataIdx) => {
                                    let content = [];
                                    menu.items.forEach((menuItem, idx) => {
                                        const allergensList = [];
                                        let intoleranceCheck = false;
                                        let mayContain = false;

                                        intolerances.forEach(intolerance => {
                                            if (intolerance.checked && menuItem.may_contain.includes(intolerance.name)) {
                                                mayContain = true;
                                                allergensList.push(intolerance.name);
                                            }
                                            if (['Vegetarian', 'Vegan'].includes(intolerance.name)) {
                                                if (intolerance.checked && !menuItem.dietary.includes(intolerance.name)) {
                                                    intoleranceCheck = true;
                                                    allergensList.push(intolerance.name);
                                                }
                                            } else {
                                                if (intolerance.checked &&
                                                    (menuItem.contains.includes(intolerance.name))) {
                                                    intoleranceCheck = true;
                                                    allergensList.push(intolerance.name);
                                                }
                                            }
                                        });

                                        const hideItemsWithMayContain = uiFilters.find(filter => filter.value === 'hide_may_contain').checked;
                                        const showCalories = uiFilters.find(filter => filter.value === 'show_calories').checked;
                                        if (intoleranceCheck || (hideItemsWithMayContain && mayContain)) {
                                            return;
                                        }

                                        content.push(
                                            <MenuItem
                                                showCalories={showCalories}
                                                key={idx}
                                                menu={menuItem}
                                                hasIntolerance={intoleranceCheck}
                                                mayContain={mayContain}
                                                highlightedAllergens={allergensList}
                                            />
                                        );
                                    })
                                    if(content.length < 1) {
                                        return null;
                                    }
                                    return (
                                        <Collapse key={menuDataIdx} title={menu.category} divider>
                                            {content}
                                        </Collapse>
                                    )
                                })
                            }
                        </>
                    }
                </ul>
            </div>
            <button className='disclaimer-btn' onClick={openDeclaration}>
                <Tooltip title='Declaration' placement='left'>
                    <i className='fa fa-warning'/>
                </Tooltip>
            </button>
        </div>
    )
}

export default AllergensMenu;